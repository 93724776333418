<template>
  <div id="app">
    <div v-if="realtimeEnabled && !!auth">
      <PulsRealtime
        :auth="auth"
        :app="'web'"
        :debug="debugRealtime"
        :environment="environment"
        :messageReceivedCallback="realtimeMessageReceived"
        :connectedCallback="realtimeConnectedCallback"
        :disconnectedCallback="realtimeDisconnectedCallback"
        class="ms-3 me-3"
      />
    </div>
    <PulsBackground background-variant="8">
      <ThemeSelector />
      <NavBar
        v-if="isLoggedIn"
        :auto-dark-theme="true"
        :submenu-items="navItems"
        current-item="overview"
        :user-is-admin="userIsAdmin"
        :on-click-item="onClickNavItem"
        :show-logo="true"
      />
      <router-view />
    </PulsBackground>
  </div>
</template>

<script>
import { NavBar } from '@puls-solutions/pulsmeter-menu'
import { PulsRealtime } from '@puls-solutions/puls-realtime-vue'
import DevOps from '@puls-solutions/puls-devops-utils'
import {
  PulsBackground,
  ThemeSelector
} from '@puls-solutions/puls-ui-components'
export default {
  name: 'app',
  components: {
    NavBar,
    PulsBackground,
    ThemeSelector,
    PulsRealtime
  },
  data() {
    return {
      realtimeConnectionId: null
    }
  },
  mounted() {
    if (
      '-ms-scroll-limit' in document.documentElement.style &&
      '-ms-ime-align' in document.documentElement.style
    ) {
      // detect it's IE11
      const handleIERouting = () => {
        var currentPath = window.location.hash.slice(1)
        if (this.$route.path !== currentPath) {
          this.$router.push(currentPath)
        }
      }
      window.removeEventListener('hashchange', handleIERouting)
      window.addEventListener('hashchange', handleIERouting, false)
    }
  },
  computed: {
    realtimeEnabled() {
      return this.environment === 'dev'
    },
    environment() {
      return DevOps.WebApp.getEnvironment({})
    },
    auth() {
      const user = this.$store.getters.loggedInUser
      return user?.signInUserSession?.idToken?.jwtToken
    },
    currentId() {
      return 'overview' // we want side menu to display Results active regardless
    },
    userIsAdmin() {
      return this.$store.getters.isAdmin
    },
    isLoggedIn() {
      return this.$store.getters.loggedInUser && this.$store.getters.hasToken
    },
    username() {
      return this.$store.getters.userInfo.fullName
    },
    navItems() {
      return []
    },
    debugRealtime() {
      // check if query params includes 'debug'
      return this.$route.query.debug === 'true'
    }
  },
  methods: {
    realtimeMessageReceived(msg) {
      if (msg.action === 'reload') {
        window.location.reload()
      }
      if (msg.action === 'alert') {
        alert(msg.message)
      }
      if (msg.action === 'logout') {
        window.location.href = '/#/logout'
      }
    },
    realtimeConnectedCallback(msg) {
      this.realtimeConnectionId = msg.connectionId
    },
    realtimeDisconnectedCallback(msg) {
      this.realtimeConnectionId = null
    },
    onClickNavItem(item) {
      if (item.link) {
        window.location = item.link
      } else {
        this.$router.push(item)
      }
    }
  }
}
// }
</script>
