export default {
  "completePassword": {
    "button": {
      "submit": "Send"
    },
    "header": "Choose a new password",
    "placeholder": {
      "confirmPassword": "Confirm password",
      "password": "Password"
    }
  },
  "dashboard": {
    "button": {
      "goToAllResults": "All results",
      "myActions": "MY ACTIONS",
      "ourCompanyResults": "OUR RESULTS"
    },
    "card": {
      "body":
        "Here you can find the personal actions that you have chosen to do. You can also see your result and how it compares to the overall organization.",
      "title": "Actions and results"
    },
    "greetings": {
      "day": "Hi, {username}!",
      "evening": "Good evening, {username}!",
      "morning": "Good morning, {username}!",
      "night": "Good night, {username}!",
      "simple": "Hello!"
    },
    "hello": "Hello {username}!",
    "integrations": {
      "title": "Integrations"
    },
    "introHeadline": "{productTitle} at {organizationName}",
    "settings": {
      "ar": "Arabic",
      "body": "Here you can change settings for your account.",
      "da": "Danish",
      "de": "German",
      "en": "English",
      "false": "Norwegian",
      "fr": "French",
      "logout": {
        "button": {
          "title": "Log out"
        },
        "confirmation": {
          "areyousure":
            "If you logout you need to request a new one one-time code in order to login again.",
          "cancel": "Cancel",
          "false": "Cancel",
          "no": "Cancel",
          "title": "Do you want to logout?",
          "true": "Logout",
          "yes": "Logout"
        },
        "loggedin": "You're logged in as {username}."
      },
      "no": "Norwegian",
      "sv": "Swedish",
      "title": "Settings"
    }
  },
  "error": {
    "CodeMismatchException":
      "The one-time code you entered is invalid. Please double-check it and try again.",
    "couldNotLoadResults": "Could not load results, please try again.",
    "couldNotLoadSurveys": "Could not load surveys, please try again.",
    "emailAtLeast3Letter": "E-mail address must contain at least 3 letters",
    "emptyEmail": "Enter your e-mail address",
    "emptyOrganization": "Enter your organization name",
    "emptyPassword": "Enter a password",
    "EternalLoopError":
      "Your account appears to be invalid. Please contact Puls support.",
    "ExpiredCodeException":
      "The one-time code you entered is too old and can for security reasons not be used.",
    "InvalidParameterException": "Missing required parameter Session.",
    "InvalidPasswordException": "Please choose a more secure password.",
    "LimitExceededException":
      "Too many failed attempts. You must wait a while before you try again.",
    "NotAuthorizedException":
      "It's currently not possible to login with your user account.",
    "notMatchPassword": "Passwords do not match!",
    "OrganizationNotFoundException":
      "We could not find an organization with that name, please try again.",
    "UserNotFoundException":
      "We could not find  {username}. Try click on the link in the invitation email again!"
  },
  "forgotten": {
    "backToLogin": "Cancel",
    "helptext":
      "Here you can reset your password. You will receive an e-mail to the above e-mail address with a code, which you will enter in the next step.",
    "helptext-quickstart":
      "We just sent you a temporary code to {email}. Enter it below, and choose a password.",
    "helptext2": "Choose a secure password.",
    "input": {
      "code": "Code found in email",
      "organization": "Organization",
      "password": "New password",
      "username": "E-mail address"
    },
    "requestcode": {
      "submit": "Continue"
    },
    "setNewPassword": {
      "submit": "Start!"
    },
    "wait": "Please wait..."
  },
  "general": {
    "defaultProductTitle": "Puls⁺"
  },
  "help": {
    "card": {
      "body":
        "Is there anything we can help you with? Just click the button below to go to the help center.",
      "title": "Help and support"
    },
    "description": "Don't worry, we got your back.",
    "title": "HELP"
  },
  "ie": {
    "detailedText":
      "The Puls platform can be used with Internet Explorer but it is an old web browser that does not comply to the modern standards of the web. We therefore recommend that you use one of the browsers below. You can also use your mobile phone.",
    "detailedTitle": "About Internet Explorer",
    "readMore": "Read more",
    "warning":
      "You're using Internet Explorer. We recommend that you switch to another web browser for best experience."
  },
  "integrations": {
    "addIntegrationSuccess": "Integration successfully added!",
    "allIntegrations": "All integrations",
    "apps": {
      "sftp": {
        "connectionInfo": "Will upload to {dir} on SFTP server.",
        "connectionTitle": "SFTP Connection",
        "title": "SFTP"
      },
      "slack": {
        "connectionInfo": "Connected to {teamName} channel {channel}",
        "connectionTitle": "Slack to {appDetail}",
        "title": "Slack"
      }
    },
    "back": "Back",
    "buttons": {
      "deleteIntegration": {
        "help": "This will remove {type} from Puls."
      }
    },
    "connectedSince": "Connected since {date}",
    "empty": {
      "description":
        "By adding an integration you can make Puls connect to your own service. Select button below to connect to a service."
    },
    "errors": {
      "invalidIncomingIntegration":
        "Invalid incoming data for integration! Cannot continue."
    },
    "events": {
      "surveyCompleted": {
        "description":
          "When a survey is completed results for {groupTitle} are automatically sent to integration.",
        "title": "Survey completed"
      },
      "title": "Choose event when integration should be contacted"
    },
    "groups": {
      "title": "Choose group to connect to {appName}"
    },
    "integrationTo": "Integration to {appName}",
    "nothingSelected": "Select...",
    "setupIntegration": {
      "saveButtonTitle": "Save",
      "title": "Setup integration to {appName}"
    },
    "title": "Integrations"
  },
  "loggedOut": {
    "loginButton": {
      "title": "Log in again"
    },
    "title": "You're now logged out!"
  },
  "login": {
    "button": {
      "forgotPassword": "Forgot password",
      "submit": "Login"
    },
    "captchaLegal": {
      "disclaimer":
        "This site is protected by reCAPTCHA and the Google {toc} and {privacyPolicy} apply.",
      "policy": "Privacy Policy",
      "toc": "Terms of Service"
    },
    "header": "Login",
    "placeholder": {
      "email": "E-mail address",
      "organization": "Organization",
      "password": "Password"
    }
  },
  "navbar": {
    "welcome": "Welcome"
  },
  "notFound": {
    "alert": "This page does not exist. Please go back to where you came from",
    "backButton": "Overview",
    "title": "Not found"
  },
  "results": {
    "participants": "{participants} participants",
    "surveys": {
      "completed": {
        "latest": "Latest completed survey",
        "title": "Results for completed surveys"
      },
      "emptyResult": "Soon you'll be able to see results here!",
      "published": {
        "title": "Check results"
      }
    }
  },
  "setup": {
    "attributes": {
      "birthdate": {
        "info":
          "This is needed in order to collect statistics based on different age groups.",
        "label": "Birth date"
      },
      "birth_date": {
        "info":
          "This is needed in order to collect statistics based on different age groups.",
        "label": "Birth date"
      },
      "custom:employed_since": {
        "info":
          "The date when you were employed. It doesn't have to be the exact date. Skip making a choice if you're not employed.",
        "label": "Employed since"
      },
      "employment_date": {
        "info":
          "The date when you were employed. It doesn't have to be the exact date. Skip making a choice if you're not employed.",
        "label": "Employed since"
      },
      "family_name": {
        "label": "Last name"
      },
      "gender": {
        "other": "Other",
        "female": "Female",
        "info":
          "We ask about gender so that it can included it in statistics. You don't have to select annything if you don't want to.",
        "label": "Choose gender:",
        "male": "Male",
        "wontsay": "Prefer not to say"
      },
      "given_name": {
        "label": "First name"
      },
      "locale": {
        "ar": "Arabic",
        "da": "Danish",
        "de": "German",
        "dk": "Danish",
        "en": "English",
        "es": "Spanish",
        "et": "Estonian",
        "no": "Norwegian",
        "fi": "Finnish",
        "fr": "French",
        "hr": "Croatian",
        "info":
          "Choose the language you prefer to use. This can also be changed later.",
        "it": "Italian",
        "label": "Choose language:",
        "ms": "Malay",
        "nl": "Dutch",
        "no": "Norwegian",
        "pl": "Polish",
        "ru": "Russian",
        "so": "Somali",
        "sr": "Serbian",
        "sv": "Swedish",
        "uk": "Ukranian"
      }
    },
    "button": {
      "submit": "Save",
      "tryAgain": "Try again"
    },
    "error": {
      "badPassword": "Please select a more secure password.",
      "clickLinkAgain":
        "Please click the link in the welcome e-mail once again. If the problem persists please contact support@pulsmeter.se",
      "invalid": "That link is not valid anymore, click below instead:",
      "noPassword": "You must choose a password.",
      "passwordsMustMatch": "Passwords must match.",
      "sessionError": "We encountered a problem!",
      "SSONotFound": "There is no SSO configuration for the organisation.",
      "unexpected": "There was an unexpected problem"
    },
    "header": "Get started with Puls!",
    "introText":
      "Hello{name}! You've been invited to Puls, a platform for coworker engagement. Activate your account by filling out the form below. ",
    "introTextVolontary": "Only password is mandatory to fill out.",
    "login": "Continue",
    "placeholder": {
      "confirmPassword": "Confirm the password",
      "newPassword": "Choose a password"
    }
  },
  "sso": {
    "button": {
      "continue": "Continue",
      "login": "SSO Login",
      "signout": "Sign out"
    },
    "error": {
      "generic": "There was a problem during sign-in: {error}"
    },
    "noUserAccountFound": "No user account found in organization"
  },
  "start": {
    "button": {
      "getOrganizationData": {
        "title": "Continue"
      },
      "requestCode": {
        "title": "Fetch one-time code"
      },
      "submitCode": {
        "title": "Login"
      }
    },
    "confirmRequestCode": {
      "header": {
        "description":
          "We first just want to make sure you're the right person! When you proceed a one-time-code will be sent. Use it at the next step to login to {productTitle} at {organizationName}.",
        "help":
          "You have recently requested a code. Make sure you enter the code into the window where you requested it. If you proceed here a new code will be sent."
      }
    },
    "enterCode": {
      "form": {
        "code": {
          "placeholder": "one-time code"
        }
      },
      "header": {
        "deliveryMedium": {
          "EMAIL": "e-mail",
          "SMS": "text message"
        },
        "description":
          "A one-time code has been sent to you via {deliveryMedium}. Leave this window open and come back here to enter the code below:",
        "title": "Welcome to {productTitle}!"
      },
      "help": {
        "noCodeDescriptionEmail":
          "The code was sent to you via email to the email address that includes '{destination}'. The sender is \"Puls\". It can take a minute or two for the code to arrive. If you can't find it check your spam folder. Make sure that you keep this window open and enter the code above.",
        "noCodeDescriptionSMS":
          "The code was sent as a text message to your mobile phone and the phone number that includes '{destination}'. It can take a minute or two for the code to arrive. If you don't recognize the phone number, please contact your administrator.",
        "noCodeLinkTitle": "Didn't you get a code?",
        "noCodeStartOverLinkTitle": "Start over"
      }
    },
    "enterEmail": {
      "form": {
        "username": {
          "placeholder": "e-mail address"
        }
      },
      "header": {
        "description": "Enter your e-mail address at {organizationName}:"
      }
    },
    "enterOrganization": {
      "form": {
        "organization": {
          "placeholder": "organization name"
        }
      },
      "header": {
        "description":
          "Start by entering the name of the organization you belong to.",
        "invalid": {
          "spaces": "Don't use spaces.",
          "specialchars": "Only use letters.",
          "uppercase": "Only use lower-case letters."
        }
      }
    }
  },
  "surveys": {
    "description": "These surveys are ongoing now:",
    "descriptionOneSurveyOnly": "Select the survey below to get started!",
    "descriptionOneSurveyOnlyUserDone":
      "The survey is still ongoing. Select it to see the actions you have chosen.",
    "ended": "ended",
    "youreDone": "You're done"
  },
  "surveysToFillIn": {
    "description": "These surveys are ongoing now:",
    "descriptionOneSurveyOnly": "Select the survey below to get started!",
    "descriptionOneSurveyOnlyUserDone":
      "The survey is still ongoing. Select it to see the actions you have chosen.",
    "emptyResult":
      "Right now there's no survey waiting for you to fill in. You can take a look at your actions/comments or results here below.",
    "ended": "ended",
    "endsIn": "ends",
    "retry": "Retry",
    "title":
      "Current survey|You have a survey to fill in|There are {count} surveys waiting for you",
    "youreDone": "You're done"
  },
  "user": {
    "attributes": {
      "birthdate": {
        "info":
          "This is needed in order to collect statistics based on different age groups.",
        "label": "Birth date"
      },
      "birth_date": {
        "info":
          "This is needed in order to collect statistics based on different age groups.",
        "label": "Birth date"
      },
      "custom:employed_since": {
        "info":
          "The date when you were employed. It doesn't have to be the exact date. Skip making a choice if you're not employed.",
        "label": "Employed since"
      },
      "employment_date": {
        "info":
          "The date when you were employed. It doesn't have to be the exact date. Skip making a choice if you're not employed.",
        "label": "Employed since"
      },
      "family_name": {
        "label": "Last name"
      },
      "gender": {
        "other": "Other",
        "female": "Female",
        "info":
          "We ask about gender so that it can included it in statistics. You don't have to select annything if you don't want to.",
        "label": "Choose gender:",
        "male": "Male",
        "wontsay": "Prefer not to say"
      },
      "given_name": {
        "label": "First name"
      },
      "locale": {
        "ar": "Arabic",
        "da": "Danish",
        "de": "German",
        "en": "English",
        "es": "Spanish",
        "et": "Estonian",
        "no": "Norwegian",
        "fi": "Finnish",
        "fr": "French",
        "hr": "Croatian",
        "info": "Choose the language you prefer to use.",
        "it": "Italian",
        "label": "Choose language:",
        "ms": "Malay",
        "nl": "Dutch",
        "no": "Norwegian",
        "pl": "Polish",
        "ru": "Russian",
        "so": "Somali",
        "sr": "Serbian",
        "sv": "Swedish",
        "uk": "Ukranian",
        "zh": "Chinese"
      }
    },
    "button": {
      "cancel": "Cancel",
      "skip": "Go back",
      "submit": "Save",
      "tryAgain": "Try again"
    },
    "error": {
      "badPassword": "Please select a more secure password.",
      "clickLinkAgain":
        "Please click the link in the welcome e-mail once again. If the problem persists please contact support@pulsmeter.se",
      "invalid": "That link is not valid anymore, click below instead:",
      "noPassword": "You must choose a password.",
      "passwordsMustMatch": "Passwords must match.",
      "sessionError": "We encountered a problem!",
      "SSONotFound": "There is no SSO configuration for the organisation.",
      "unexpected": "There was an unexpected problem"
    },
    "header": "User settings",
    "introText": "Check that your user profile is correct.",
    "introTextNoAttributes": "Currently there are no settings to change here.",
    "introTextVolontary": "The information below is voluntary to fill in.",
    "login": "Continue"
  },
  "whistleblower": {
    "buttons": {
      "cancel": "Cancel",
      "done": "Ok",
      "send": "Send anonymously"
    },
    "description":
      "We provide you with the possibility to anonymously send a report about unlawful behaviour or misdemeanours that you have witnessed at {organizationName}.",
    "detailedDescription":
      "This is how it works. You enter your report in the text box below. When you send the report an email is sent to a predefined recipient at your organization with responsibility to take action. It is impossible for the recipient to see that the message was sent by you, only that it came from the Puls platform.  If you want to be contacted, make sure to include information about that in your message. If you want to stay anonymous you can use a disposable (temporary) email address or  SIM card for mobile phone.",
    "doneText":
      "Thanks for submitting your report. We will take it seriously. If you have provided contact information we'll get back to you as soon as possible!",
    "linkTitle": "Read more here",
    "linkToHelp": "https://www.puls-solutions.com/help/TODO",
    "notEnabled": {
      "description":
        "Apologies. The whistleblower feature is not enabled by your organization.",
      "title": "Not available"
    },
    "placeholder":
      "Enter your report here. Be as specific as possible and let us know if and how you want to be contacted.",
    "title": "Send anonymous report"
  }
}
