import Vue from 'vue'
import 'whatwg-fetch'
import Amplify from 'aws-amplify'
// import '@babel/polyfill'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import DevOps from '@puls-solutions/puls-devops-utils'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'bootstrap/dist/js/bootstrap.min'
import '@puls-solutions/pulsmeter-menu/dist/pulsmeter-menu.css'
import '@puls-solutions/puls-good-html/theme/dist/assets/css/style.bundle.css'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config'
import VueHelper from './helpers/VueHelper'
import i18n from './i18n'
import '@puls-solutions/puls-ui-components/dist/puls-ui-components.css'
import '@fontsource/inter/300.css'
import '@fontsource/inter/400.css'
import '@fontsource/inter/500.css'
import '@fontsource/inter/600.css'
import '@fontsource/inter/700.css'

const c = DevOps.WebApp.configToUse({ config })

// // eslint-disable-next-line no-console
// console.log(DevOps.WebApp.getEnvironment({}))
const configObject = {
  API: {
    endpoints: [
      {
        name: 'SurveyAPI',
        endpoint: c.surveyApiUrl,
        region: 'eu-west-1',
        custom_header: async () => {
          return {
            'x-api-key': c.apiKey,
            'x-puls-authorization': (await Amplify.Auth.currentSession())
              .idToken.jwtToken
          }
        }
      },
      {
        name: 'PublicAPI',
        endpoint: c.publicApiUrl,
        region: 'eu-west-1',
        custom_header: async () => {
          return {
            'x-api-key': c.apiKey
          }
        }
      },
      {
        name: 'UserAPI',
        endpoint: c.userApiUrl,
        region: 'eu-west-1',
        custom_header: async () => {
          return {
            'x-api-key': c.apiKey,
            'x-puls-authorization': (await Amplify.Auth.currentSession())
              .idToken.jwtToken
          }
        }
      },
      {
        name: 'ResultsAPI',
        endpoint: c.resultsApiUrl,
        region: 'eu-west-1',
        custom_header: async () => {
          return {
            'x-api-key': c.apiKey,
            'x-puls-authorization': (await Amplify.Auth.currentSession())
              .idToken.jwtToken
          }
        }
      },
      {
        name: 'InsightsAPI',
        endpoint: c.insightsApiUrl,
        region: 'eu-west-1',
        custom_header: async () => {
          return {
            'x-api-key': c.apiKey,
            'x-puls-authorization': (await Amplify.Auth.currentSession())
              .idToken.jwtToken
          }
        }
      }
    ]
  }
}

if (localStorage.orgData && localStorage.organizationName) {
  const orgResult = JSON.parse(localStorage.orgData)
  configObject.Auth = {
    identityPoolId: orgResult.identityPoolId,
    region: 'eu-west-1',
    userPoolId: orgResult.userPoolId,
    userPoolWebClientId: orgResult.userPoolWebClientId,
    mandatorySignIn: true,
    clientMetadata: {
      organizationName: localStorage.organizationName,
      organizationFullName: orgResult.organizationFullName,
      locale: 'en' // TODO: Use some kind of default
    }
  }
}

Amplify.configure(configObject)

Vue.use(BootstrapVue)

// Own helpers
Vue.use(VueHelper, i18n, store)
// 'sv': require('date-fns/locale/sv'),
// 'de': require('date-fns/locale/de'),
// 'fr': require('date-fns/locale/fr'),
// 'da': require('date-fns/locale/da'),
// 'pl': require('date-fns/locale/pl'),
// 'fi': require('date-fns/locale/fi'),
// 'it': require('date-fns/locale/it'),
// 'nl': require('date-fns/locale/nl'),
// 'no': require('date-fns/locale/nb'), // special here
// 'es': require('date-fns/locale/es') // special h

// const locale = localStorage.selectedLocale || c.defaultLanguage

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
